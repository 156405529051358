import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactNotification from 'react-notifications-component'
import Download from "../components/Download"

const IndexPage = () => {
  return(
    <Layout>
    <ReactNotification />
    <SEO title="Download Profilemate" />
    <Download />
  </Layout>
  )
}

export default IndexPage
